import React from "react";
import styled from "styled-components";
import background from "../images/contact/background.svg";
import backgroundDesktop from "../images/contact/backgroundDesktop.svg";
// import Name from "./common/name";

const CONTENT = {
  mainTitle: "For Enquiry",
  phone: "+852 2521 3634",
  copyright: (
    <>
      © 2024 28 Stanley | 28SS  All Rights Reserved.
    </>
  ),
  phoneHref: "+85225213634",
};

const Container = styled.section`
  padding-top: 5rem;
  background: #d0d3d4;

  @media (min-width: 1440px) {
    background-image: url("${backgroundDesktop}");
    background-position: center bottom;
    background-repeat: repeat-x;
    background-size: 1440px 594px;
  }
`;

const BackgroundContainer = styled.div`
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
`;

const Background = styled.img`
  display: block;
  width: 100%;

  @media (min-width: 576px) {
    display: none;
  }
`;

const BackgroundDesktop = styled.img`
  display: none;
  width: 100%;

  @media (min-width: 576px) {
    display: block;
  }
`;

const MainTitleContainer = styled.div`
  position: absolute;
  bottom: 33%;
  width: 100%;

  @media (min-width: 576px) {
    top: 0;
    left: 50%;
    bottom: unset;
    width: 50%;
  }
`;
const MainTitle = styled.h2`
  font-size: 3rem;
  width: 14rem;
  text-align: center;
  margin: 0 auto;
  line-height: 1;
  color: ${(props) => props.theme.background};

  @media (min-width: 576px) {
    text-align: left;
    font-size: 2rem;
    width: 10rem;
    margin: 1rem 0 0 1rem;
  }

  @media (min-width: 768px) {
    font-size: 2.75rem;
  }

  @media (min-width: 992px) {
    font-size: 3.5rem;
  }

  @media (min-width: 992px) {
    font-size: 3.5rem;
  }

  @media (min-width: 1200px) {
    font-size: 4.5rem;
  }

  @media (min-width: 1400px) {
    margin-top: 2.5rem;
  }
`;

const Phone = styled.a`
  text-align: center;
  font-size: 1.5rem;
  display: block;
  position: absolute;
  bottom: 20%;
  width: 100%;
  color: ${(props) => props.theme.background};
  font-weight: 400;
  text-decoration: none;

  @media (min-width: 576px) {
    top: 40%;
    left: 50%;
    bottom: unset;
    width: unset;
    text-align: left;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  @media (min-width: 992px) {
    font-size: 1.75rem;
  }

  @media (min-width: 1200px) {
    font-size: 2rem;
  }
`;

const Copyright = styled.p`
  text-align: center;
  font-size: 0.75rem;
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
  color: ${(props) => props.theme.background};
  @media (min-width: 576px) {
    color: ${(props) => props.theme.body};
  }
`;

// markup
const Contact = () => {
  return (
    <Container id="contact-us">
      <BackgroundContainer>
        <Background src={background} />
        <BackgroundDesktop src={backgroundDesktop} />
        <MainTitleContainer>
          <MainTitle>{CONTENT.mainTitle}</MainTitle>
        </MainTitleContainer>
        <Phone href={`tel:${CONTENT.phoneHref}`}>{CONTENT.phone}</Phone>
        <Copyright>{CONTENT.copyright}</Copyright>
      </BackgroundContainer>
    </Container>
  );
};

export default Contact;
