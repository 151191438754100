import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";
import PageContainer from "./common/pageContainer";
import fG from "../images/floorPlans/fG.svg";
import f1 from "../images/floorPlans/f1.svg";
import f2 from "../images/floorPlans/f2.svg";
import f3 from "../images/floorPlans/f3.svg";
import f5 from "../images/floorPlans/f5.svg";
import f8 from "../images/floorPlans/f8.svg";
import f9 from "../images/floorPlans/f9.svg";
import f10 from "../images/floorPlans/f10.svg";
import f18 from "../images/floorPlans/f18.svg";
import f19 from "../images/floorPlans/f19.svg";
import f26 from "../images/floorPlans/f26.svg";
import f27 from "../images/floorPlans/f27.svg";
import f28 from "../images/floorPlans/f28.svg";
import f29 from "../images/floorPlans/f29.svg";
import f30 from "../images/floorPlans/f30.svg";
import f31 from "../images/floorPlans/f31.svg";

import fR from "../images/floorPlans/fR.svg";

const fadeIn = keyframes`
 from {
   opacity: 0;
 }
 to {
   opacity: 1;
 }
`;

const fadeOut = keyframes`
 from {
   opacity: 1;
 }
 to {
   opacity: 0;
 }
`;

const fadeInAnimation = css`
  animation: ${fadeIn} 300ms ease-in;
  animation-fill-mode: forwards;
`;

const fadeOutAnimation = css`
  animation: ${fadeOut} 300ms ease-out;
  animation-fill-mode: forwards;
`;

const CONTENT = {
  mainTitle: "Floor Plan",
  floorPlans: [
    {
      name: "G/F",
      title: "Ground Floor",
      img: fG,
    },
    {
      name: "1/F",
      title: "1st Floor",
      img: f1,
    },
    {
      name: "2/F",
      title: "2nd Floor",
      img: f2,
    },
    {
      name: "3/F",
      title: "3rd Floor",
      img: f3,
    },
    {
      name: "5/F - 6/F",
      title: "5th - 6th Floor",
      img: f5,
    },
    {
      name: "8/F",
      title: "8th Floor",
      img: f8,
    },
    {
      name: "9/F",
      title: "9th Floor",
      img: f9,
    },
    {
      name: "10/F - 16/F",
      title: "10th - 16th Floor",
      img: f10,
    },
    {
      name: "18/F",
      title: "18th Floor",
      img: f18,
    },
    {
      name: "19/F - 25/F",
      title: "19th - 25th Floor",
      img: f19,
    },
    {
      name: "26/F - 27/F",
      title: "26th - 27th Floor",
      // img: f26,
      imgs: [f26, f27],
    },
    // {
    //   name: "27/F",
    //   title: "27th Floor",
    //   img: f27,
    // },
    {
      name: "28/F - 29/F",
      title: "28th - 29th Floor",
      // img: f28,
      imgs: [f28, f29],
    },
    // {
    //   name: "29/F",
    //   title: "29th Floor",
    //   img: f29,
    // },
    {
      name: "30/F - 31/F",
      title: "30th - 31st Floor",
      // img: f30,
      imgs: [f30, f31],
    },
    // {
    //   name: "31/F",
    //   title: "31st Floor",
    //   img: f31,
    // },
    {
      name: "R/F",
      title: "Rooftop",
      img: fR,
    },
  ],
};

const Container = styled.section`
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: #d0d3d4;
`;

const MainTitle = styled.h2`
  font-size: 3rem;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 4rem;
  }

  @media (min-width: 992px) {
    font-size: 4.5rem;
    text-align: left;
  }
`;

const FloorPlans = styled.div`
  margin-top: 4rem;
`;

const FloorPlanSelectors = styled.div`
  margin: 1rem -0.5rem -0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 992px) {
    justify-content: flex-start;
    max-width: 520px;
  }
`;

const FloorPlanSelector = styled.div`
  margin: 0.5rem;
  padding: 0.25rem 1rem;
  border: 1px solid ${(props) => props.theme.darkBody};
  border-radius: 99px;
  color: ${(props) => (props.active ? "#fff" : props.theme.darkBody)};
  background: ${(props) => (props.active ? props.theme.body : "transparent")};
  cursor: pointer;
`;

const FloorPlanContainer = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
  max-width: 520px;
  margin: 0 auto;

  ${(props) => (props.active ? fadeInAnimation : fadeOutAnimation)};

  @media (min-width: 992px) {
    max-width: unset;
    display: ${(props) => (props.active ? "flex" : "none")};
  }
`;

const FloorPlanTitle = styled.h3`
  font-size: 2.5rem;
  text-align: center;

  //@media (min-width: 768px) {
  //  font-size: 3rem;
  //}
  //
  //@media (min-width: 992px) {
  //  font-size: 3.5rem;
  //}
  @media (min-width: 992px) {
    text-align: left;
  }
`;

const FloorPlanText = styled.div``;

const FloorPlanImgContainer = styled.div`
  margin-top: 1rem;
  width: 100%;

  @media (min-width: 992px) {
    margin-top: 0;
    flex: 1;
  }
`;

const FloorPlanImg = styled.img`
  display: block;
  width: 100%;
`;

const FloorPlanInnerContainer = styled.div`
  @media (min-width: 992px) {
    width: 40%;
    margin-right: 2rem;
  }

  @media (min-width: 1200px) {
    width: 45%;
  }
`;

// markup
const FloorPlan = () => {
  const [active, setActive] = useState(0);

  return (
    <Container id="floor-plan">
      <PageContainer>
        <MainTitle>{CONTENT.mainTitle}</MainTitle>
        <FloorPlanSelectors>
          {CONTENT.floorPlans.map((floorPlan, index) => {
            return (
              <FloorPlanSelector
                key={index}
                active={active === index}
                onClick={() => {
                  setActive(index);
                }}
              >
                {floorPlan.name}
              </FloorPlanSelector>
            );
          })}
        </FloorPlanSelectors>
        <FloorPlans>
          {CONTENT.floorPlans.map((floorPlan, index) => {
            return (
              <FloorPlanContainer key={index} active={active === index}>
                <FloorPlanInnerContainer>
                  <FloorPlanTitle>{floorPlan.title}</FloorPlanTitle>
                  <FloorPlanText>{floorPlan.description}</FloorPlanText>
                </FloorPlanInnerContainer>
                <FloorPlanImgContainer>
                  {floorPlan.img && <FloorPlanImg src={floorPlan.img} />}
                  {floorPlan.imgs &&
                    floorPlan.imgs.map((img, index) => {
                      return <FloorPlanImg src={img} key={index} />;
                    })}
                </FloorPlanImgContainer>
              </FloorPlanContainer>
            );
          })}
        </FloorPlans>
      </PageContainer>
    </Container>
  );
};

export default FloorPlan;
