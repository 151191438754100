import React, { useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import PageContainer from "./common/pageContainer";
import outline from "../images/building/outline.json";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import lottieLight from "lottie-web/build/player/lottie_light";
import usePrevious from "use-previous";
import InnerPageContainer from "./common/innerPageContainer";
// import heroMobile from "../images/hero/heroMobile.json";
//0.25
//-0.3
//-0.8
// -1
// -1.5
import shape28 from "../images/shape28.svg";
import Name from "./common/name";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeInAnimation = css`
  animation: ${fadeIn} 750ms;
  animation-fill-mode: forwards;
`;

const CONTENT = {
  floors: [
    {
      position: 1,
      title: "Ground Floor",
      text: (
        <>
          <Name />
          ’s ground floor features clean marble walls, high ceiling and glass
          windows, inviting customers in to use its facilities. Shops would
          enjoy higher foot traffic at the street level, making it highly
          suitable for increasing brand exposure.
        </>
      ),
      frame: [61, 120],
      framePosition: 1,
    },
    {
      position: 0.1,
      title: "Floor 1-25",
      text: (
        <>
          These floors are occupied by the acclaimed The Executive Center, which
          utilises <Name />
          ’s strong infrastructure to offer high-end office space solutions.
          These flexible working spaces are tailored to maximise productivity,
          empowering entrepreneurs and assisting in their growth.
        </>
      ),
      frame: [121, 180],
      framePosition: 0.1,
    },
    {
      position: -0.4,
      title: "Floor 26-29",
      text: (
        <>
          <Name /> floors 26, 27 to 28, 29 are state of the art duplex floors
          that are designed and built to have a high degree of flexibility,
          specialised in catering for either restaurants or office spaces that
          desire quality.
        </>
      ),
      frame: [181, 239],
      framePosition: -0.4,
    },
    {
      position: -0.7,
      title: "Floor 30, 31 and Rooftop",
      text: (
        <>
          The floors at the top of <Name /> are exclusive spaces for dining.
          Combined with stunning sea views and modern interior, these floors are
          excellent for restaurants that wish to provide their customers with a
          first-rate culinary experience.
        </>
      ),
      frame: [240, 299],
      framePosition: -0.7,
    },
  ],
};

const Container = styled.section`
  height: 200vh;
  background: ${(props) => props.theme.lightPrimary};
`;

const InnerContainer = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: hidden;
`;

const BuildingInnerContainer = styled(InnerPageContainer)`
  position: relative;
`;

const Gradient = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 3rem;
  background: linear-gradient(
    0deg,
    rgba(216, 199, 158, 0) 0%,
    rgba(216, 199, 158, 1) 100%
  );
  z-index: 1;
`;

const BuildingOutlineContainer = styled.div`
  height: 100vh;
  position: absolute;
  left: -1.5rem;
  top: 0;
  width: 50%;

  @media (min-width: 576px) {
    left: 0;
    width: 40%;
  }

  @media (min-width: 992px) {
    left: 0;
    width: 30%;
  }
`;

const BuildingOutlineInnerContainer = styled.div`
  padding-top: 478%;
  width: 100%;
  position: relative;
  transform: translateY(-100%);
`;

const BuildingOutline = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Descriptions = styled.div`
  width: 60%;
  right: -1.5rem;
  height: 100vh;
  margin-left: auto;
  position: relative;

  @media (min-width: 576px) {
    right: 0;
    width: 50%;
  }
`;

const DescriptionContainer = styled.div`
  position: absolute;
  top: 40%;
  transform: translateY(-50%);

  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: opacity 250ms ease-in-out;
`;

const DescriptionTitle = styled.h2`
  //color: ${(props) => props.theme.primary};
  font-size: 1.75rem;
  line-height: 1.25;

  @media (min-width: 576px) {
    font-size: 2rem;
  }

  @media (min-width: 992px) {
    font-size: 3rem;
  }
`;

const DescriptionText = styled.p`
  margin-top: 1rem;
`;

const Shape = styled.img`
  position: absolute;
  bottom: 2rem;
  right: 0;
  display: block;
  width: 5rem;
  opacity: 0;

  ${(props) => props.show && fadeInAnimation};

  @media (min-width: 768px) {
    bottom: 3rem;
    //width: 6rem;
  }

  @media (min-width: 992px) {
    width: 8rem;
  }

  @media (min-width: 1200px) {
    width: 10rem;
  }
`;

const START = 0.7;
const END = -2;
const ANIMATION_START = 0.8;
const ANIMATION_END = -2;
const SHOW_SHAPE = -64;

// markup
const Building = () => {
  const containerRef = useRef();
  const outlineRef = useRef();
  const animationRef = useRef();
  const [offset, setOffset] = useState(-100);
  const [active, setActive] = useState(null);
  const [frame, setFrame] = useState(0);
  const prevFrame = usePrevious(frame);

  useEffect(() => {
    if (!isNaN(frame) && !isNaN(prevFrame)) {
      if (frame <= 0 || frame > CONTENT.floors.length) {
        return;
      }
      if (frame > prevFrame) {
        animationRef.current.playSegments(
          [
            CONTENT.floors[frame - 1].frame[0],
            CONTENT.floors[frame - 1].frame[1],
          ],
          true
        );
      } else if (prevFrame > frame) {
        animationRef.current.playSegments(
          [
            CONTENT.floors[frame - 1].frame[1],
            CONTENT.floors[frame - 1].frame[0],
          ],
          true
        );
      }
    }
  }, [frame]);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const position = currPos.y / (containerRef.current.offsetHeight / 2);
      let next;
      if (position > START) {
        next = -110;
      } else if (position < END) {
        next = 100;
      } else {
        next = -(210 / 2.7) * position - 55.555;
      }

      if (offset !== next) {
        setOffset(next);
      }

      let nextActive;

      if (position > START || position < END) {
        nextActive = null;
      } else {
        for (let i = 0; i < CONTENT.floors.length; i++) {
          const floor = CONTENT.floors[i];
          if (position < floor.position) {
            nextActive = i;
          }
        }
      }

      if (nextActive !== active) {
        setActive(nextActive);
      }

      let nextFrame;
      if (position > ANIMATION_START) {
        nextFrame = 0;
      } else if (position < ANIMATION_END) {
        nextFrame = CONTENT.floors.length + 1;
      } else {
        for (let i = 0; i < CONTENT.floors.length; i++) {
          const floor = CONTENT.floors[i];
          if (position < floor.position) {
            nextFrame = i + 1;
          } else {
            break;
          }
        }
      }

      if (nextFrame !== frame) {
        setFrame(nextFrame);
      }
    },
    [active],
    containerRef
  );

  useEffect(() => {
    animationRef.current = lottieLight.loadAnimation({
      container: outlineRef.current, // the dom element that will contain the animation
      loop: false,
      autoplay: false,
      animationData: outline,
    });

    // animationRef.current.setSpeed(2);

    return () => {
      animationRef.current.destroy();
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <InnerContainer>
        <Gradient />
        <PageContainer>
          <BuildingInnerContainer>
            <BuildingOutlineContainer>
              <BuildingOutlineInnerContainer
                style={{
                  transform: `translateY(${offset}%)`,
                }}
              >
                <BuildingOutline ref={outlineRef} />
              </BuildingOutlineInnerContainer>
            </BuildingOutlineContainer>
            <Descriptions>
              {CONTENT.floors.map((floor, index) => {
                return (
                  <DescriptionContainer key={index} active={index === active}>
                    <DescriptionTitle>{floor.title}</DescriptionTitle>
                    <DescriptionText>{floor.text}</DescriptionText>
                  </DescriptionContainer>
                );
              })}
              <Shape src={shape28} show={offset > SHOW_SHAPE} />
            </Descriptions>
          </BuildingInnerContainer>
        </PageContainer>
      </InnerContainer>
    </Container>
  );
};

export default Building;
