import React, { useCallback } from "react";
import styled from "styled-components";
import PageContainer from "./common/pageContainer";
import { useEmblaCarousel } from "embla-carousel/react";
import { StaticImage } from "gatsby-plugin-image";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import background from "../images/gallery/background.png";

const CONTENT = {
  mainTitle: "Gallery",
};

const Container = styled.section`
  padding-top: 5rem;
  background-color: #fff;
  //background-image: url("${background}");
  //background-size: 132px 155px;
  position: relative;
`;

const MainTitle = styled.h2`
  font-size: 3rem;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 4rem;
  }

  @media (min-width: 992px) {
    font-size: 4.5rem;
  }
`;

const Embla = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 4rem;
  z-index: 1;
`;

const EmblaContainer = styled.div`
  display: flex;
`;

const EmblaSlide = styled.div`
  position: relative;
  flex: 0 0 80%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media (min-width: 768px) {
    flex: 0 0 50%;
  }

  @media (min-width: 992px) {
    flex: 0 0 40%;
  }

  @media (min-width: 1200px) {
    flex: 0 0 30%;
  }

  @media (min-width: 1400px) {
    flex: 0 0 25%;
  }
`;

const Background = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: ${(props) => props.theme.background};
  height: 2rem;
`;

const ArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 2rem;
  cursor: pointer;
`;

const LeftArrowContainer = styled(ArrowContainer)`
  left: 0;
  padding-left: 0;
`;

const LeftArrow = styled(BsChevronLeft)`
  color: #fff;
  display: block;
  width: 2rem;
  height: 2rem;

  @media (min-width: 768px) {
    width: 3rem;
    height: 3rem;
  }
`;

const RightArrowContainer = styled(ArrowContainer)`
  right: 0;
  padding-right: 0;
`;
const RightArrow = styled(BsChevronRight)`
  color: #fff;
  display: block;
  width: 2rem;
  height: 2rem;

  @media (min-width: 768px) {
    width: 3rem;
    height: 3rem;
  }
`;

// markup
const Gallery = () => {
  const [emblaRef, embla] = useEmblaCarousel({ loop: true });

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const images = {
    preview: [
      <StaticImage
        src={`../images/gallery/gallery1.jpg`}
        alt={"Lift lobby"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery2.jpg`}
        alt={"Lift lobby"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery3.jpg`}
        alt={"Lift lobby"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery4.jpg`}
        alt={"Lift lobby"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery5.jpg`}
        alt={"Lift lobby"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery6.jpg`}
        alt={"Facade at night"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery7.jpg`}
        alt={"Facade in the day"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery8.jpg`}
        alt={"The Executive Center"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery9.jpg`}
        alt={"The Executive Center"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery10.jpg`}
        alt={"The Executive Center"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery11.jpg`}
        alt={"The Executive Center"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery12.jpg`}
        alt={"The Executive Center"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery13.jpg`}
        alt={"The Executive Center"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery14.jpg`}
        alt={"The Executive Center"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery15.jpg`}
        alt={"The Executive Center"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery16.jpg`}
        alt={"The Executive Center"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
      <StaticImage
        src={`../images/gallery/gallery17.jpg`}
        alt={"The Executive Center"}
        placeholder="blurred"
        layout="fullWidth"
        aspectRatio={4 / 3}
      />,
    ],
  };

  return (
    <Container id="gallery">
      <PageContainer>
        <MainTitle>{CONTENT.mainTitle}</MainTitle>
      </PageContainer>
      <Embla ref={emblaRef}>
        <EmblaContainer>
          {images.preview.map((image, index) => {
            return <EmblaSlide key={index}>{image}</EmblaSlide>;
          })}
        </EmblaContainer>
        <LeftArrowContainer
          onClick={() => {
            scrollPrev();
          }}
        >
          <LeftArrow />
        </LeftArrowContainer>
        <RightArrowContainer
          onClick={() => {
            scrollNext();
          }}
        >
          <RightArrow />
        </RightArrowContainer>
      </Embla>
      <Background />
    </Container>
  );
};

export default Gallery;
