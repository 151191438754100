import React from "react";
import styled, { css, keyframes } from "styled-components";
import { useInView } from "react-intersection-observer";
import PageContainer from "./common/pageContainer";
import fade28 from "../images/fade28.svg";
import shape28 from "../images/shape28.svg";

const fadeAndSlideInFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeAndSlideInFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeAndSlideInFromLeftAnimation = css`
  animation: ${fadeAndSlideInFromLeft} 700ms;
  animation-fill-mode: forwards;
`;

const fadeAndSlideInFromRightAnimation = css`
  animation: ${fadeAndSlideInFromRight} 700ms;
  animation-fill-mode: forwards;
`;

const FeatureContent = styled.div`
  position: relative;
`;

const FeatureTitle = styled.h3`
  font-size: 3rem;
  //color: ${(props) => props.theme.primary};
  position: relative;
  z-index: 1;
  line-height: 1;
  max-width: 520px;
  margin-left: auto;

  @media (min-width: 576px) {
    transform: translateX(-2rem);
  }

  @media (min-width: 768px) {
    transform: translateX(-4rem);
    font-size: 4rem;
  }

  @media (min-width: 992px) {
    font-size: 4.5rem;
  }

  @media (min-width: 1200px) {
    margin-left: 32rem;
  }
`;

const FeatureTitleAlt = styled(FeatureTitle)`
  display: none;
`;

const FeatureInnerContent = styled.div`
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const FeatureDescriptionContainer = styled.div`
  margin-top: 4rem;
  position: relative;

  @media (min-width: 1200px) {
    flex: 1;
  }
`;

const FeatureDescription = styled.p`
  @media (min-width: 576px) {
    max-width: 520px;
    margin-left: auto;
  }

  @media (min-width: 1200px) {
    margin-left: 0;
  }
`;

const FeatureImageMainContainer = styled.div`
  margin-top: -1rem;
  margin-left: -2rem;
  margin-right: -2rem;
  opacity: 0; // animation

  @media (min-width: 576px) {
    max-width: 552px;
    margin-left: auto;
  }
  @media (min-width: 768px) {
    margin-right: -4rem;
    margin-top: -2rem;
    max-width: 584px;
  }

  @media (min-width: 1200px) {
    max-width: unset;
    margin-left: 32rem;
  }
`;

const FeatureImageAsideContainer = styled.div`
  margin-top: 4rem;
  margin-left: -2rem;
  margin-right: -2rem;
  opacity: 0; // animation

  @media (min-width: 576px) {
    margin-left: 0;
    margin-right: 0;
    width: 25rem;
  }

  @media (min-width: 992px) {
    padding-right: 2rem;
    margin-top: 0;
    flex: 1;
  }

  @media (min-width: 1200px) {
    //max-width: unset;
    width: 32rem;
    flex: unset;
    padding-right: 4rem;
  }
`;

const FeatureBackgroundContainer = styled.div`
  position: absolute;
  left: -2rem;
  top: 50%;
  right: -2rem;

  @media (min-width: 576px) {
    left: unset;
    //right: -2rem;
    width: 25rem;
  }

  @media (min-width: 768px) {
    right: -4rem;
    width: 30rem;
  }

  @media (min-width: 992px) {
    top: unset;
    bottom: -5.2rem;
  }
`;

const FeatureEvenBackground = styled.img`
  display: block;
  width: 100%;
`;

const FeatureShape = styled.img`
  display: none;
  position: absolute;
  bottom: 2rem;
  left: 0;
  width: 8rem;
  transform: translateY(50%);

  @media (min-width: 992px) {
    width: 10rem;
  }

  //@media (min-width: 1200px) {
  //  width: 14rem;
  //}
`;

const Container = styled.div`
  padding-top: 2rem;
  overflow: hidden;
  ${(props) => props.background && `background: ${props.background};`}

  &:nth-child(even) {
    //background: #fff;

    @media (min-width: 576px) {
      ${FeatureTitle} {
        display: none;
      }

      ${FeatureTitleAlt} {
        display: block;

        @media (min-width: 576px) {
          transform: none;
          margin-left: 0;
        }
      }
    }

    ${FeatureDescriptionContainer} {
      margin-top: 2rem;
    }

    ${FeatureImageMainContainer} {
      transform: translateX(-100%);
      //margin-top: 0;
      ${(props) => props.inView && fadeAndSlideInFromLeftAnimation};

      @media (min-width: 576px) {
        margin-top: 0;
        margin-left: -2rem;
        margin-right: unset;
      }

      @media (min-width: 768px) {
        margin-left: -4rem;
      }

      @media (min-width: 1200px) {
        margin-right: 32rem;
      }
    }

    ${FeatureDescription} {
      margin-top: 1rem;

      @media (min-width: 576px) {
        margin-left: 0;
      }
    }

    ${FeatureImageAsideContainer} {
      transform: translateX(100%);
      ${(props) => props.inView && fadeAndSlideInFromRightAnimation};
      @media (min-width: 576px) {
        margin-left: auto;
      }

      @media (min-width: 992px) {
        padding-right: 0;
        padding-left: 2rem;
      }

      @media (min-width: 1200px) {
        padding-right: 0;
        padding-left: 4rem;
      }
    }

    ${FeatureInnerContent} {
      @media (min-width: 992px) {
        flex-direction: row;
      }
    }

    ${FeatureBackgroundContainer} {
      @media (min-width: 576px) {
        left: -2rem;
        right: unset;
        top: 80%;
      }

      @media (min-width: 768px) {
        left: -4rem;
      }

      @media (min-width: 992px) {
        top: unset;
        bottom: -5.2rem;
      }
    }

    ${FeatureShape} {
      @media (min-width: 992px) {
        display: block;
      }
    }
  }

  &:nth-child(odd) {
    ${FeatureImageMainContainer} {
      transform: translateX(100%);
      ${(props) => props.inView && fadeAndSlideInFromRightAnimation};
    }

    ${FeatureImageAsideContainer} {
      transform: translateX(-100%);
      ${(props) => props.inView && fadeAndSlideInFromLeftAnimation};
    }
  }

  &:first-child {
    color: ${(props) => props.theme.lightPrimary};

    ${FeatureTitle} {
      color: ${(props) => props.theme.lightPrimary};
    }
  }

  @media (min-width: 576px) {
    padding-bottom: 5rem;
  }
`;

const Feature = ({ data, images }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  return (
    <Container inView={inView} ref={ref} background={data.background}>
      <PageContainer>
        <FeatureContent>
          <FeatureTitle>{data.title}</FeatureTitle>
          <FeatureImageMainContainer>{images.main}</FeatureImageMainContainer>
          <FeatureInnerContent>
            <FeatureDescriptionContainer>
              <FeatureTitleAlt>{data.title}</FeatureTitleAlt>
              <FeatureDescription>{data.description}</FeatureDescription>
              <FeatureBackgroundContainer>
                <FeatureEvenBackground src={fade28} />
              </FeatureBackgroundContainer>
            </FeatureDescriptionContainer>
            <FeatureImageAsideContainer>
              {images.aside}
            </FeatureImageAsideContainer>
          </FeatureInnerContent>
          <FeatureShape src={shape28} />
        </FeatureContent>
      </PageContainer>
    </Container>
  );
};

export default Feature;
