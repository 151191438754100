import React from "react";
import styled, { css, keyframes } from "styled-components";
import { useInView } from "react-intersection-observer";
import PageContainer from "./common/pageContainer";
import { StaticImage } from "gatsby-plugin-image";
import Name from "./common/name";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeInAnimation = css`
  animation: ${fadeIn} 750ms;
  animation-fill-mode: forwards;
`;

const DescriptionParagraph = styled.p`
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }
`;

const CONTENT = {
  mainTitle: "Location",
  description: (
    <>
      <DescriptionParagraph>
        <Name /> is only a convenient 5 minutes away from Central MTR station,
        15 minutes to the Hong Kong Airport Express Station and 1 hour to the
        Hong Kong – Shenzhen border. Operating near distinguished banking and
        commercial giants, 28 Stanley’s convenient location allows firms to make
        full use of Hong Kong as an international financial hub.
      </DescriptionParagraph>
      <DescriptionParagraph>
        Additionally, <Name /> stands near the Central-Mid-Levels escalators,
        ensuring easy access to all parts of central. <Name /> is surrounded by
        many iconic cultural spots like Pottinger Street, a stylish street made
        of granite slabs, known for photo shoots and appearing in movie scenes.
        This street also leads all the way to Tai Kwun (大館), a popular centre
        for heritage and arts, frequented by both locals and tourists.
      </DescriptionParagraph>
    </>
  ),
};

const Container = styled.section`
  background: ${(props) => props.theme.body};
  color: #D0D3D4;
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media (min-width: 992px) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  @media (min-width: 1200px) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  @media (min-width: 1400px) {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
`;

const MainTitle = styled.h2`
  font-size: 3rem;
  // color: ${(props) => props.theme.lightPrimaryAlt};

  @media (min-width: 1200px) {
    font-size: 5rem;
  }
`;

const Description = styled.div`
  margin-top: 1rem;
  max-width: 520px;
`;

const ContentContainer = styled.div`
  margin-top: 2rem;

  @media (min-width: 768px) {
    width: 50%;
    margin-top: 0;
    margin-left: 1rem;
  }

  @media (min-width: 1200px) {
    margin-left: 2rem;
  }
`;

const MapContainer = styled.div`
  opacity: 0;
  ${(props) => props.inView && fadeInAnimation};

  @media (min-width: 768px) {
    width: 50%;
    margin-right: 1rem;
  }

  @media (min-width: 1200px) {
    margin-right: 2rem;
  }
`;

const InnerContainer = styled.div`
  max-width: 520px;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: unset;
    display: flex;
    align-items: center;
  }
`;

// markup
const Location = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
    rootMargin: `0px 0px -20%`,
  });
  return (
    <Container id="location" ref={ref}>
      <PageContainer>
        <InnerContainer>
          <MapContainer inView={inView}>
            <StaticImage
              src="../images/location/map.png"
              alt={"map"}
              loading="eager"
            />
          </MapContainer>
          <ContentContainer>
            <MainTitle>{CONTENT.mainTitle}</MainTitle>
            <Description>{CONTENT.description}</Description>
          </ContentContainer>
        </InnerContainer>
      </PageContainer>
    </Container>
  );
};

export default Location;
