// import React from "react";

import styled from "styled-components";

const PageContainer = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 1440px;
  margin: 0 auto;

  @media (min-width: 768px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`;

export default PageContainer;
