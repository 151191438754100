// import React from "react";

import styled from "styled-components";

const InnerPageContainer = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

export default InnerPageContainer;
