import React from "react";
import { ThemeProvider } from "styled-components/macro";

import Layout from "../components/layout";
import Header from "../components/header";
import Hero from "../components/hero";
import Features from "../components/features";
import Gallery from "../components/gallery";
import Location from "../components/location";
import FloorPlan from "../components/floorPlan";
import Contact from "../components/contact";
import Building from "../components/building";

const theme = {
  background: "#fff",
  primaryGray: "#D0D3D4",
  primaryGreen: "#33504A",
  body: "#33504A",
  darkBody: "#33504A",
  lightPrimary: "#D8C79E",
  primary: "#CB6015",
  lightPrimaryAlt: "#EFB758",
};

// markup
const IndexPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Header />
        <main>
          <Hero />
          <Features />
          <Gallery />
          <Building />
          <Location />
          <FloorPlan />
          <Contact />
        </main>
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;
