import React from "react";
import "normalize.css";
import "./global.css";

import { Helmet } from "react-helmet";
import icon from "../images/icon.png";

const Layout = ({ children }) => {
  // todo: fill in below
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>28 Stanley [ 28SS ] New Iconic Building in Modern Hong Kong</title>
        <link rel="canonical" href="https://www.28stanley.com" />
        <meta property="og:title" content="28 Stanley [ 28SS ] - New Iconic Building in Modern Hong Kong" />
        <meta
          property="og:description"
          content="28 Stanley [ 28SS ] is a 31-floor building located in the heart of Central, Hong Kong. An excellent venue for business and leisure, 28SS is an icon in modern Hong Kong. "
        />
        <meta
          property="og:image"
          content={`https://www.28stanley.com${icon}`}
        />
        <meta property="og:url" content="https://www.28stanley.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:site_name" content="28 Stanley [ 28SS ]" />
        <meta name="twitter:image:alt" content="28 Stanley [ 28SS ], an iconic 31-floor new building in the heart of Central, Hong Kong" />
      </Helmet>
      {children}
    </>
  );
};

export default Layout;
