import React from "react";
import styled from "styled-components";

const Container = styled.span`
  white-space: nowrap;
  font-variant: small-caps;
  font-weight: 600;
  color: ${(props) => props.theme.primary};
`;

const Name = (props) => {
  return <Container {...props}>28 Stanley [ 28SS ]</Container>;
};

export default Name;
