import React from "react";
import styled from "styled-components";
import PageContainer from "./common/pageContainer";
import logo from "../images/tecLogo.svg";

const CONTENT = {
  leftMenu: [
    { title: "About", link: "#about" },
    { title: "Features", link: "#features" },
    { title: "Gallery", link: "#gallery" },
  ],
  rightMenu: [
    { title: "Location", link: "#location" },
    { title: "Floor plan", link: "#floor-plan" },
    { title: "Contact us", link: "#contact-us" },
  ],
};

const Container = styled.header``;

const InnerContainer = styled.div`
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    height: 8rem;
  }

  @media (min-width: 1200px) {
    height: 10rem;
  }

  @media (min-width: 1400px) {
    height: 12rem;
  }
`;

const LeftMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  flex: 1;

  @media (min-width: 992px) {
    display: flex;
    align-items: center;
  }
`;

const MenuItem = styled.li`
  margin-left: 2rem;

  &:first-child {
    margin-left: 0;
  }

  @media (min-width: 1200px) {
    margin-left: 3rem;
  }

  @media (min-width: 1400px) {
    margin-left: 4rem;
  }
`;

const MenuLink = styled.a`
  color: ${(props) => props.theme.darkBody};
  text-decoration: none;
  display: block;
`;

const MenuLinkSpecial = styled(MenuLink)`
  border: 1px solid ${(props) => props.theme.darkBody};
  padding: 0.25rem 0.75rem;
  border-radius: 99px;
`;

const RightMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  flex: 1;

  @media (min-width: 992px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const LogoContainer = styled.div``;

const Logo = styled.img`
  display: block;
  height: 4.5rem;

  @media (min-width: 1200px) {
    height: 5rem;
  }

  @media (min-width: 1400px) {
    height: 7rem;
  }
`;

const Header = () => {
  return (
    <Container>
      <PageContainer>
        <InnerContainer>
          <LeftMenu>
            {CONTENT.leftMenu.map((menuItem, index) => {
              return (
                <MenuItem key={index}>
                  <MenuLink href={menuItem.link}>{menuItem.title}</MenuLink>
                </MenuItem>
              );
            })}
          </LeftMenu>
          <LogoContainer>
            <Logo src={logo} />
          </LogoContainer>
          <RightMenu>
            {CONTENT.rightMenu.map((menuItem, index) => {
              return (
                <MenuItem key={index}>
                  {index === 2 ? (
                    <MenuLinkSpecial href={menuItem.link}>
                      {menuItem.title}
                    </MenuLinkSpecial>
                  ) : (
                    <MenuLink href={menuItem.link}>{menuItem.title}</MenuLink>
                  )}
                </MenuItem>
              );
            })}
          </RightMenu>
        </InnerContainer>
      </PageContainer>
    </Container>
  );
};

export default Header;
